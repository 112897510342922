import React, { useEffect } from 'react'
import {
  useTrackToggle,
  MicIcon,
  MicDisabledIcon,
} from '@livekit/components-react'
import MicrophoneOpen from '../../static/images/Microphone-open.png'
import MicrophoneClose from '../../static/images/Microphone-close.png'

export const TrackToggle = React.forwardRef(({ showIcon, ...props }, ref) => {
  const { buttonProps, enabled, toggle } = useTrackToggle(props)
  useEffect(() => {
    if (props.state === 'speaking') {
      toggle(false)
    } else {
      toggle(true)
    }
  }, [props.state])
  console.log('source', props.source)
  return (
    <div ref={ref} {...buttonProps}>
      {/* {(showIcon ?? true) && (enabled ? <MicIcon /> : <MicDisabledIcon />)} */}

      <img
        width={80}
        src={enabled ? MicrophoneOpen : MicrophoneClose}
        alt=""
        style={{ cursor: 'pointer' }}
      ></img>
      {props.children}
    </div>
  )
})

export default TrackToggle
