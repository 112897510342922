import {
  LiveKitRoom,
  useVoiceAssistant,
  RoomAudioRenderer,
  useLocalParticipant,
  useEnsureRoom,
} from '@livekit/components-react'
import { UUID } from 'uuidjs'
import VoiceGif from '../../static/images/output.gif'
import SafariIcon from '../../static/images/yuyinren.png'
import AnswerGif from '../../static/images/answer1.gif'
import ListenIcon from '../../static/images/listenIcon.png'

import http from '../../utils/http'

import { AI_URL, LINK_URL, HTTP_URL, ACCESS_CODE } from '../../utils/url'
import React, { useCallback, useEffect, useState, useRef } from 'react'
import { Spin } from 'antd'
import TrackToggle from './TrackToggle'

const useGetState = (initVal) => {
  const [state, setState] = useState(initVal)
  const ref = useRef(initVal)

  const setStateCopy = (newVal) => {
    ref.current = newVal
    setState(newVal)
  }
  const getState = () => ref.current
  return [state, setStateCopy, getState]
}

export default function AIBubble() {
  let sessionId = UUID.generate()
  const [data, setData, getData] = useGetState({})
  const [loadingWel, setLoadingWel] = useState(true)
  const [percent, setPercent] = useState(0)

  const [agentState, setAgentState] = useState('disconnected')
  const [status, setStatus, getStatus] = useGetState('') //1:welcome(欢迎页) 2:voice(语音访谈) 3:text(文本访谈) 4.end(结束页)

  const [connectionDetails, updateConnectionDetails] = useState(undefined)

  useEffect(() => {
    getInterview_info() //获取访谈基本信息
  }, [])
  useEffect(() => {
    if (agentState === 'initializing' || agentState === 'connecting') {
      // setLoadingWel(true)
    } else if (agentState !== 'disconnected' && agentState !== 'connecting') {
      setStatus('voice')
      setLoadingWel(false)
    }
    // else if (agentState === 'disconnected') {
    //   setStatus('welcome')
    // }
  }, [agentState])

  const onStopVoice = () => {
    updateConnectionDetails(undefined)
    setStatus('end')
  }

  const getInterview_info = () => {
    setLoadingWel(true)
    const access_code = ACCESS_CODE
    http
      .post(AI_URL + '/api/query_interview_info', {
        access_code,
        session_id: sessionId,
      })
      .then((res) => {
        if (!res.data) {
          //访谈被删除页面
          // navigate('/interview2/delete')
        } else {
          setLoadingWel(false)
          let data = res.data
          if (data.interview_execute.status === 0) {
            //访谈已经关闭
            // navigate('/interview2/cannotInterview')
          }

          setData(data)
          setStatus('welcome')
        }
      })
  }
  const onConnectButtonClicked = useCallback(async () => {
    setLoadingWel(true)
    // Generate room connection details, including:
    //   - A random Room name
    //   - A random Participant name
    //   - An Access Token to permit the participant to join the room
    //   - The URL of the LiveKit server to connect to
    //
    // In real-world application, you would likely allow the user to specify their
    // own participant name, and possibly to choose from existing rooms to join.

    let roomPrefix = 'OpenaiDirectorMode'

    const participantIdentity = sessionId
    const roomName = `${roomPrefix}_${Math.floor(Math.random() * 10_000)}`
    // 从API获取token和LIVEKIT_URL
    const response = await fetch(`${AI_URL}/api/generateInterviewToken`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_name: participantIdentity,
        room_name: roomName,
        language: 'English',
        source_type: '',
        interview_outline: getData().interview_outline,
        interview_execute: getData().interview_execute,
        temp_recording: getData().temp_recording,
      }),
    })
    const tokenData = await response.json()

    updateConnectionDetails({
      serverUrl: tokenData.data.LIVEKIT_URL,
      roomName,
      participantToken: tokenData.data.token,
      participantName: participantIdentity,
    })
  }, [])

  const getDiv = () => {
    if (status === 'voice') {
      return (
        <div
          style={{
            width: '100%',
            paddingTop: '50px',
          }}
        >
          {agentState === 'speaking' && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <img width={'60%'} src={VoiceGif} alt="" />
              <div style={{ marginTop: 10 }}>
                <img width={150} src={SafariIcon} alt="" />
              </div>
            </div>
          )}
          {agentState === 'listening' && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <img width={'70%'} src={AnswerGif} alt="" />
              <div style={{ marginTop: 10 }}>
                <img src={ListenIcon} alt="" />
              </div>
            </div>
          )}
          {agentState !== 'disconnected' && agentState !== 'connecting' && (
            <TrackToggle
              source="microphone"
              state={agentState}
              style={{ position: 'absolute', left: '-9999px' }}
            />
          )}
          {/* {displayText} */}
        </div>
      )
    } else if (status === 'welcome') {
      return (
        <div
          style={{
            paddingTop: '50px',
            marginLeft: '20px',
            marginRight: '20px',
          }}
        >
          <div
            style={{
              textAlign: 'center',
              color: '#000000',
              fontWeight: 500,
              fontSize: 20,
              fontFamily: 'Calibri',
            }}
          >
            Do you need help? Or want to share your feedback?
          </div>

          <div
            style={{
              paddingTop: '20px',
              textAlign: 'center',
              color: '#000000',
              fontWeight: 500,
              fontSize: 20,
              fontFamily: 'Calibri',
            }}
          >
            Simply talk to Ava!
          </div>

          <div
            style={{
              paddingTop: '50px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              onClick={onConnectButtonClicked}
              style={{
                border: '5px solid #ffff54',
                width: '130px',
                height: '130px',
                borderRadius: '100px',
                backgroundColor: '#030000',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                color: '#ffff54',
                fontWeight: 500,
                fontSize: 20,
                fontFamily: 'Racing Sans One',
                cursor: 'pointer',
              }}
            >
              Talk to Ava Now
            </div>
          </div>
        </div>
      )
    } else if (status === 'end') {
      return (
        <div
          style={{
            paddingTop: '100px',
            marginLeft: '20px',
            marginRight: '20px',
          }}
        >
          <div
            style={{
              textAlign: 'center',
              color: '#000000',
              fontWeight: 500,
              fontSize: 20,
              fontFamily: 'Calibri',
            }}
          >
            Thank you for talking with Ava, we will revert at our soonest.
          </div>
        </div>
      )
    }
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: '#e2ebe7',
        borderRadius: '10px',
      }}
    >
      <Spin
        spinning={loadingWel}
        style={{
          marginTop: '50%',
          marginLeft: '50%',
          position: 'absolute',
          zIndex: 9999,
        }}
      ></Spin>
      <LiveKitRoom
        token={connectionDetails?.participantToken}
        serverUrl={connectionDetails?.serverUrl}
        connect={connectionDetails !== undefined}
        audio={true}
        video={false}
        onMediaDeviceFailure={onDeviceFailure}
        onDisconnected={() => {
          updateConnectionDetails(undefined)
        }}
      >
        <SimpleVoiceAssistant
          onStateChange={setAgentState}
          onStopVoice={onStopVoice}
          onChangeProcess={setPercent}
        />

        {getDiv()}
        {/* <ControlBar
          onConnectButtonClicked={onConnectButtonClicked}
          agentState={agentState}
        /> */}
        <RoomAudioRenderer />
      </LiveKitRoom>
    </div>
  )
}

function SimpleVoiceAssistant(props) {
  const { state, audioTrack } = useVoiceAssistant()
  useEffect(() => {
    props.onStateChange(state)
    console.log('state', state)
  }, [props, state])

  // const { localParticipant } = useLocalParticipant()
  const room = useEnsureRoom()
  room.unregisterRpcMethod('isEndInterview')
  room.registerRpcMethod('isEndInterview', async (data) => {
    let params = JSON.parse(data.payload)
    console.log('结束访谈了: ', params.is_end)

    props.onStopVoice()
  })
  room.unregisterRpcMethod('infoProcess')
  room.registerRpcMethod('infoProcess', async (data) => {
    let params = JSON.parse(data.payload)
    console.log('访谈进度: ', params.process)
    props.onChangeProcess(params.process)
  })
  return <div></div>
}

function onDeviceFailure(error) {
  console.error(error)
  alert(
    'Error acquiring camera or microphone permissions. Please make sure you grant the necessary permissions in your browser and reload the tab',
  )
}
